
  const openerIcon       = document.getElementById('opener_icon');
  const textOnClick      = document.getElementById('text_on_click');
  const startingPageRef  = document.getElementById('starting_page');
  const smallLogoFade    = document.getElementById('small_logo_ease');
  const triangles        = document.getElementById('triangles-start');
  const logoText         = document.getElementById('logo_text');
  const logoFrontPage    = document.getElementById('logo-front-page');
  const navFronPage      = document.getElementById('nav-front-page');

  // const animationCookie = document.cookie = 'animation=collapsed; path=/;';
  // console.log(animationCookie)

//   function remove() {
//   setInterval(() => {
//     document.querySelector('.container').style.height = 'unset';
//     document.querySelector('.container').style.overflow = 'unset';
//     document.querySelector('.triangles').style.display = 'none';
//   }, 3000);
// }

  const animation = () => {
  let mediumDevice =  window.matchMedia("(min-width: 768px)")
  textOnClick.classList.add('text-blend-in');
  textOnClick.classList.remove('hide');
  startingPageRef.classList.add('active')
  triangles.classList.add('triangle-fade');
  openerIcon.classList.add('blend-out');
  logoText.classList.add('blend-out');
  logoFrontPage.classList.add('logo-front-page-animation');
  // document.querySelector('.container').style.height = 'unset'
  // document.querySelector('.container').style.overflow = 'unset'

  if(mediumDevice.matches) {
  document.querySelector("details").style.transform = 'translateY(0vh)';
  document.querySelector("header").style.height = '25vh';
}
  // remove();
};

  window.addEventListener('touchmove', () => {
  animation();
});
  window.addEventListener('wheel', () => {
  animation();
});
  openerIcon.addEventListener('click', () => {
  animation();
});